rem(n)
    unit(n * 10 / 750, 'rem')

$icon_size = rem(120)

.home-slide
    .t-slide-view
        height 6rem !important
        img
            width 100%
            height 100%

.tingle-intro
    -webkit-transition background-color 1s
    transition background-color 1s

.tingle-svg svg
    width 8rem
    height 4rem
    fill #fff

.home-grid-item
    width 100%
    height 100%
    .icon-text
        font-size 16px
        margin-top rem(16)
        text-align center
    .home-grid-icon
        position relative
        .icon-bg svg
            width $icon_size
            height $icon_size
        .icon-is
            position absolute
            top rem(30)
            left rem(30)
        .icon-is svg
            width rem(60)
            height rem(60)
            fill #fff
    .home-grid-icon svg
        width $icon_size
        height $icon_size

    &.hover
        background #f6f6f6

for color, index in #fa8169 #6eb5eb #74d18d #D79F6E #8790db #03A9F4 #af86d5 #df3670 #9ad26e #d86d6d #7CB342 #FBC02D #676767 #7E57C2 #8790db
    .icon-color{index} svg
        fill color


