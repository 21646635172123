@import '~saltui/build/dd.min.css';

body
    font-size 14px
    color #333
    background-color #eee

.page-list
    .t-group-head
        padding 10px 15px

.about-item
    height 66px
    .pic
        width 40px
        height 40px
        border-radius 50%
    .info
        padding-left 10px

.slab-item
    padding-top 8px
    padding-bottom 8px

.list-change-tab
    margin 30px 8px 30px 16px

.list-change-theme
    margin 30px 16px 30px 8px

.demo-t-list-wrap
    padding: 10px

.demo-t-list-wrap-single
    display: block
    padding: 8px 10px

.demo-t-list-img
    width: 28px
    height: 28px
    border-radius: 50%
    margin-right: 10px
    margin-left: 5px

.demo-t-list-text-content
    margin-right: 10px

.demo-t-list-text-content-single
    line-height: 28px

.demo-t-list-title
    color: #222
    font-size: 16px
    line-height: 22px
    padding-bottom: 4px

.demo-t-list-title-single
    font-size: 17px
    color: #222
    padding-bottom: 0

.demo-t-list-text
    color: #999
    font-size: 12px
    line-height: 18px
    padding-bottom: 4px

.demo-t-list-time
    color: #999
    font-size: 12px

.demo-t-list-title-tag
    display: inline-block;
    background: #ccc;
    color: #fff;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 3px;
    margin-left: 5px;

.demo-t-list-img-right
    -webkit-flex-direction: row-reverse
    flex-direction: row-reverse

.demo2-t-list-wrap
    padding-top: 10px
    background: #f4f4f4

.demo2-t-list-title
    background: #fff
    height: 30px
    line-height: 30px
    padding-left: 15px
    padding-right: 12px
    -webkit-justify-content: space-between
    justify-content: space-between

.demo2-t-list-title-content
    color: #222
    font-size: 15px

.demo2-t-list-time
    color: #999
    margin-left: 5px

.demo2-t-list-img-wrap
    width: 100%
    height: 5rem

.demo2-t-list-img
    width: 100%
    height: 5rem

.demo3-t-list-wrap
    padding: 10px

.demo3-t-list-img
    margin-right: 10px

.demo3-t-list-title
    color: #222
    font-size: 14px
    line-height: 20px

.demo3-t-list-text
    color: #999
    font-size: 14px
    line-height: 20px

.demo3-t-list-time
    color: #666
    font-size: 12px
    line-height: 18px

.demo3-t-list-cricle
    display: block
    width: 8px
    height: 8px
    border-radius: 50%
    background: #f04631
    float: right

.t-group-head
    padding 9px 15px

.icon 
    width: 1em
    height: 1em
    vertical-align: -0.15em
    fill: currentColor
    overflow: hidden

.scroller
    position fixed
    top 0
    bottom 0
    left 0
    right 0

.scrollerBottom
    position fixed
    top 0
    bottom 60px
    left 0
    right 0

.actionBtnContainer
    padding 15px 10px

.topActionBtnContainer
    padding 5px
    
.fixedActionBtnContainer
    padding 10px 10px
    position fixed
    bottom 0px
    left 0px
    right 0px

.loadMoreDataText
    color #999999
    text-align center
    padding 10px 15px

.t-demo-title
    height 0px
    padding-bottom 0px
    padding-top 0px

.t-dialog-title 
    color rgba(0,0,0,.8)
    font-size 17px
    font-weight 700
    padding 18px 8px 13px
    
.t-dialog-body
    text-align left
    padding 18px 24px 13px
    line-height 22px
    font-size 14px
    margin 0 auto
    color rgba(0,0,0,.6)
    overflow auto

.tileInStock
    color #0000CD

.tilePlanningCompleted
    color  #FFA500

.tileManufactureCompleted
    color green
    
.tileSold
    color #FF0000

.tileReserved
    color #330066

.tileDiscarded
    color #B7B7B7

.unitPriceArea
    margin-top 10px
    line-height 20px
    vertical-align middle

.unitPriceLabel
    width 40px
    text-align center
    margin-right 10px

.deductedReasonLabel
    width 60px
    text-align center

.unitPriceText
    color red

.balanceText
    color #333

.unitPriceTextBox
    text-align right
    width 50px
    border-style solid
    border-color #aaaaaa
    border-width 1px
    border-radius 2px
    height 20px

.deductedTextPosition
    position absolute
    right 95px

.salesOrderDetailItem
    padding-top 10px
    padding-bottom 5px

.readOnlyTextBox
    color #ccc

.unitPriceLabelForPolishedSlabNotInStock
    width 50px
    text-align left

.checkboxField
    padding-top 9px

.disabledItem
    color: #999999

.titleArea
    top 45px

.titleAreaWithTab
    top 126px

.filterCollapsed
    top 46px

.appliedPriceItemSelectorSty
    top 83px

.filterCollapsedWithTab
    top 90px

.filterCollapsedWithTwoTab
    top 132px

.filterForOneExpanded
    top 148px

.filterForOneWithTabExpanded
    top 191px

.filterForTwoExpanded
    top 194px

.filterForTwoWithTabExpanded
    top 235px

.filterForThreeExpanded
    top 238px

.filterForThreeWithTabExpanded
    top 281px

.filterForThreeWithTwoTabExpanded
    top 324px

.filterForFourExpanded
    top 284px

.filterForFourWithTabExpanded
    top 327px
    
.filterForFourWithTwoTabExpanded
    top 366px

.filterForFiveExpanded
    top 330px

.filterForFiveWithTabExpanded
    top 372px

.filterForFiveWithTwoTabExpanded
    top 414px

.filterForSixExpanded
    top 378px

.filterForSixWithTabExpanded
    top 455px

.filterForSixWithTwoTabExpanded
    top 460px

.filterForSevenWithTabExpanded
    top 465px

.filterForSevenWithTwoTabExpanded
    top 506px

.filterForSevenExpanded
    top 424px

.filterForEightExpanded
    top 467px

.filterForEightWithTabExpanded
    top 513px

.filterForEightWithTwoTabExpanded
    top 554px

.filterForNineExpanded
    top 516px

.filterForNineWithTabExpanded
    top 562px

.filterForTenWithTabExpanded
    top 610px

.filterForTenExpanded
    top 561px

.filterForElevenExpanded
    top 609px

.filterForElevenWithTabExpanded
    top 650px

.filterForTwelveExpanded
    top 657px

.filterForTwelveWithTabExpanded
    top 698px

.filterForThirteenWithTabExpanded
    top 739px

.filterForFourteenWithTabExpanded
    top 787px

.blockPurchased
    color #2F4F4F

.blockInStock
    color #0000CD

.blockManufacturing
    color  #FFA500

.blockProcessed
    color green
    
.blockSold
    color #FF0000

.blockReserved
    color #330066

.blockDiscarded
    color #B7B7B7

.login
    padding 30px
    display block
    font-size 20px

.stoneBundleInStock
    color #0000CD

.stoneBundleManufacturing
    color  #FFA500

.stoneBundleCheckedOut
    color green
    
.stoneBundleSold
    color #FF0000

.stoneBundleReserved
    color #330066

.stoneBundleDiscarded
    color #B7B7B7

.paddingRight
    padding-right 10px

.slabBottom
    padding-bottom 15px

.normalSO
    color green

.abnormalSO
    color red

.approvingSO
    color blue

.waitingSettlement
    color #003C9D

.draftSO
    color #000080

.priorityStyl
    margin-left 2px

.t-button-approve
    background-color #79CC70

.separator
    border none
    border-top 1px dashed #CCCCCC
    margin-top 4px
    padding-top 4px
    line-height 20px

.unitPriceStyl
    margin-left 10px

.kuma-collapse-content>.kuma-collapse-content-box
    padding-top 0px
    padding-bottom 0px

.getTopStatus
    margin-top 1px

.getHeaderStyl
    padding-top 4px

.progressText
    color blue

.progressTextArea
    margin-top 10px
    text-align right

.sodItem
    height 88px

.red
    color red

.unitAreaLabel
    width 40px
    text-align center

.unitPercentLabel
    width 90px
    text-align center

.lineHeight
    line-height 30px

.footerInfo
    padding 0 10px 0 0
    margin 10px 0
    position fixed
    right 0px
    bottom 0px

.iconwidth
    width 35px

.unitStockingText
    color #999

.stockingAreaDetailItem
    padding-left 50px
    margin-top 5px

.priceRight
    margin-right 10px

.unitStockingArea
    margin-top 5px

.unitStockingLabel
    width 40px
    text-align center
    margin-right 10px

.unitStockingAreaText
    color blue

.approvalStyl
    position fixed
    bottom 0
    left 0
    right 0

.stockingAreaStyl
    padding 15px 0px
    position fixed
    bottom 0px
    left 0px
    right 0px

.cascadeSelectFieldBackgroundStyl
    background-color #F0F0F0

.height
    width 100%
    font-size 16px
    line-height 30px
    padding 10px 15px

.lineHeiht
   line-height 40px

.fillingCompletedTab
    color green

.importantInfo
    color red

.immediateWO
    color red

.urgentWO
    color #ff8000

.returnToUpdateIconArea
    padding-right 15px

.notStartedWO
    color #ff9933

.materialRequisitionWO
    color #3333ff

.trimmingWO
    color #6600cc

.sawingWO
    color #003366

.determineMP
    color #2F4F4F

.fillingWO
    color #330066

.polishingWO
    color green

.cancelledWO
    color #B7B7B7

.manufacturingWO
    color #330066

.polishingQEFinishedState
    color green

.pendingPolishingQEState
    color #ff8000

.readOnlyState
    color #ccc

.returnToFillingIconArea
    padding-right 15px

.returnToFillingIcon
    vertical-align middle
    line-height 40px

.discardedClo
    color red

.textWidth
    text-align center
    width 45px
    border-width 0px
    height 30px
    
.textField
    width 50px
    text-align center
    padding-top 5px

.dialogTitleClass
    font-size: 14px
    color black
    padding-left 16px
    text-align: left

.dialogContentClass
    font-size: 20px
    color black

.dialogTextClass
    padding-top 8px
    padding-bottom 8px
    font-size: 14px
    color black

.asterisk
    color red

.materialSpecArea
    padding-left 50px

.materialSpecIconArea
    padding-left 11px
    padding-top 11px
    margin-right 0px

.placeholderArea
    height 20px
    width 20px

.list-item
    padding-top 10px
    padding-bottom 10px

.expandedIconStyl
    margin-right 10px

.iconField
    padding-top 5px

.listItemContentArea
    margin auto 0

.materialSpecSeparatorWithIcon
    border none
    border-top 1px dashed #CCCCCC
    margin-top 4px
    padding-top 4px
    margin-right 10px

.materialSpecSeparator
    border none
    border-top 1px dashed #CCCCCC
    margin-top 4px
    padding-top 4px

.centerStyl
    line-height 25px
    vertical-align middle

.manufacturingProcessBtnArea
    margin 2px 0px 2px 0px

.nextProcessBtn
    height 38px
    line-height 36px
    margin 0 2px
    padding-left 0px
    padding-right 0px

.unitPriceAreaForProcessingSpec
    width 50px
    text-align center
    margin-left 10px
    
.processingSpecSeparator
    border none
    border-top 1px dashed #CCCCCC
    margin-top 4px
    padding-top 4px
    margin-right 10px

.salesOrderDetailPrice
    padding-left:10px

.t-button-normal
    margin 0 4px

.t-PR8
    padding-right 0px

 //适配OPPO
.fillingQAButton
    padding-right 0px
    margin 0 -1px

.textScopeWidth
    width 35px
    text-align center
    padding-top 5px

.scopeValueTextBox
    text-align center
    width 90px
    border-width 0px
    height 30px

.scanStyle
    margin-left 25px
    text-align center

.deleteTile
    margin-right 15px

.deleteTileList
    margin-right 5px

.scrollerTileBottom
    position fixed
    bottom 45px
    left 0
    right 0

.footerAboveActionButton
    bottom 55px

.timeLineStyl
    padding-top 10px
    padding-right 16px
    padding-left 16px
    font-size 16px

.trimmingSelectorTimeLineStyl
    padding 5px 10px 5px 50px
    font-size 16px

.trimmingInfoTimeLineStyl
    padding 5px 10px 5px 65px
    font-size 16px

.referencePriceLabel
    width 40px

.referencePriceOperatorLabel
    width 40px
    position absolute
    right 110px

.referencePriceArea
    margin-top 5px
    line-height 20px
    vertical-align middle

.referenceOperatorStyl
    position absolute
    right 48px
    width 60px

.subTotalStyl
    position absolute
    right 16px
    
.referencePriceText
    margin-left 5px
    color #097BF7
    font-size 11px

.referencePriceTextDecoration
    text-decoration line-through

.deductedSizeText
    text-align center
    position absolute
    right 110px

.deductedSizeLabel
    text-align center
    position absolute
    right 158px

.referencePriceColor
    color #097BF7

.backgroundColor
    background #00B2BF

.tagArea
    margin 2px 0px 2px 0px

.tagStyl
  border : 1px solid #ffadd2
  display: inline-block
  height: auto
  padding:0 7px
  border-radius:2px
  line-height:20px
  font-size:14px

.magentaTag
  color: #eb2f96
  background: #fff0f6
  border-color: #ffadd2

.volcanoTag
  color: #fa541c
  background: #fff2e8
  border-color: #ffbb96

.redTag
  color: #f5222d
  background: #fff1f0
  border-color: #ffa39e

.goldTag
  color: #faad14
  background: #fffbe6
  border-color: #ffe58f

.greenTag
  color: #52c41a
  background: #f6ffed
  border-color: #b7eb8f

.cyanTag
  color: #13c2c2
  background: #e6fffb
  border-color: #87e8de

.blueTag
  color: #1890ff
  background: #e6f7ff
  border-color: #91d5ff

.geekblueTag
  color: #2f54eb
  background: #f0f5ff
  border-color: #adc6ff

.purpleTag
  color: #722ed1
  background: #f9f0ff
  border-color: #d3adf7

.orangeTag
  color: #fa8c16
  background: #fff7e6
  border-color: #ffd591

.referencePriceIcon
    margin-left: 3px
    vertical-align: middle
    line-height: 20px
    padding-bottom: 2px

.packerFieldSty
    line-height: 46px
    overflow: hidden
    height: 46px

.noticeMessageSty
    font-size: 16px

.t-notice-bar .t-notice-bar-content .notice-content-message
    display:table-cell
    vertical-align: middle
    line-height: unset

.t-notice-bar .notice-info 
    background: rgba(255,0,0,0.45)

.t-notice-bar .t-notice-bar-content
    padding: 6px 15px

.bundleWithSamePrefixStatusWarning
    color: #fa8c16
    font-size: 14px
    line-height: 20px

.bundleWithSamePrefixStatusOK
    color: #52c41a
    font-size: 14px
    line-height: 20px

.outputRatesRangeSymbol
    width 15px
    text-align center
    padding-top 5px

.outputRatesPercentageInput
    text-align center
    width 80px
    border-width 0px
    height 30px

.slabGradeInfoArea
    line-height 20px
    vertical-align middle

.slabGradeSelectorInput
    font-size 16px
    text-align center
    width 110px
    line-height 25px
    border-width 0px

.slabGradeCentered
    display flex
    justify-content center
    align-items center

.slabGradePriceInfoGradeName
    font-size 16px
    text-align center
    width 95px
    line-height 25px
    border-width 0px