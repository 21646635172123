.popup-body
  display block

.popup-mask
  background-color rgba(0, 0, 0, 0.8)
  position fixed
  left 0
  right 0
  top 0
  bottom 0
  width 100%
  height 100%
  z-index 1000

.popup-cancel
  position fixed
  right 0
  top 0
  z-index 1300

.icon-cancel
  position fixed
  right 0
  top 0
  z-index 1300
  opacity 1 

.popup-button
  z-index 1200
  position fixed
  width 300px
  bottom 5px
  left 9%
 
.popup-view
  z-index 1100
  position fixed
  left 0
  right 0
  top 0
  bottom 0
  display flex
  align-items center
  justify-content center
 
.popup-image
  z-index 1100
  margin 0
  flex-shrink 0
  