.t-table-header .t-table-header-main{
    background-color: white
}

.t-number-info-number{
    color: #097BF7
}

.t-calendar-field-show-box.h-layout div:first-child {
    margin-bottom: 12px
}

.poReportColor{
    background-color: white
}

.tableTitle{
    text-align: center
    font-weight: bolder
}

.t-calendar-field .t-field-layout-h-label, .t-calendar-field-content
{
    display:unset
}

.t-calendar-field-value .date-text{
    margin-right:unset
}