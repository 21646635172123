.ESIcon-icon
	position relative
	font-size 10px

.ESIcon-icon-svg
	vertical-align middle
	background transparent

.ESIcon-icon-mask
	position absolute
	top 0
	left 0
	width 100%
	height 100%

.ESIcon-icon-smallfont
	font-size 5px